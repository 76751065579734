import * as React from "react"
import Img from 'gatsby-image'
import '../scss/components/resourceQuote.scss'

const resourceQuote = ({ title, desc, img, author, link }) => (
  <div className="resource__quote">
    {img && <img alt={"Portrait of " + author} src={img.fluid.src} loading="lazy" />}
    <div className="quote__content">
      {title && <h3 className="h5 text--blue-light">{title}</h3>}
      {desc ? <p>{desc}</p> : <p></p>}
      {author && <p className="quote__author">{author}</p>}
      {link && <a href="#">Estimate Equipment Costs</a>}
    </div>
  </div> 
)

export default resourceQuote