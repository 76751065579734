import * as React from "react"
import get from "lodash/get"
import Img from "gatsby-image"
import "../scss/components/photoGrid.scss"

class PhotoGrid extends React.Component {
  render() {
    const listItems = get(this, "props.data.listItems")

    return (
      <ul className="photo-grid">
        {listItems.map(item => (
          <li key={item.id} className="photo-grid__item flow">
            <Img
              alt={item.listItemImage.description}
              className="photo-grid__image"
              loading="lazy"
              fluid={item.listItemImage.fluid}
            />
          </li>
        ))}
      </ul>
    )
  }
}

export default PhotoGrid
